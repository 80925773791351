import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <footer >
      <a href="#" className="footer_logo">Scroll Up</a>      
    </footer>
  )
}

export default Footer